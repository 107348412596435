let Videos = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 视频分类列表
     * @param success
     * @param error
     * @constructor
     */
    this.Categories = function (success, error) {
        var url = ServiceBaseAddress + '/api/Video/Categories';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 新增视频分类
     * @param category
     * @param success
     * @param error
     * @constructor
     */
    this.AddCategories = function (category, success, error) {
        var url = ServiceBaseAddress + '/api/Video/Manager/Category';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, category,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);

    }
    /**
     * 获取视频上传凭证
     * @param category
     * @param success
     * @param error
     * @constructor
     */
     this.GetVideoCertificate = function (item, success, error) {
         
        var url = ServiceBaseAddress + '/api/Video/GetVideoCertificate';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);

    }
    /**
     * 刷新视频上传凭证
     * @param category
     * @param success
     * @param error
     * @constructor
     */
     this.RefreshVideoCertificate = function (videoId, success, error) {
        var url = ServiceBaseAddress + '/api/Video/RefreshVideoCertificate?videoId='+videoId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);

    }
    /**
     * 编辑视频分类
     * @param category
     * @param success
     * @param error
     * @constructor
     */
    this.EditCategories = function (category, success, error) {
        if (category) {
            var url = ServiceBaseAddress + '/api/Video/Manager/Category';
            TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, category,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
    }

    /**
     * 删除视频分类
     * @param categoryId
     * @param success
     * @param error
     * @constructor
     */
    this.RemoveCategories = function (categoryId, success, error) {
        var url = ServiceBaseAddress + '/api/Video/Manager/Category/' + categoryId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 获取个人/机构/总后台自己发布的视频
     * @param {*} categoryId 
     * @param {*} type 
     * @param {*} userId 
     * @param {*} organizationId 
     * @param {*} keyWord 
     * @param {*} pageIndex 
     * @param {*} success 
     * @param {*} error 
     */
    this.GetVideos = function (categoryId, type, userId, organizationId, limits, keyWord, pageIndex, state, success, error) {
        var url = ServiceBaseAddress + '/api/Video/GetMyselfVideo';
        if (categoryId) {
            url += `/${categoryId}`;
        }
        else {
            url += '/0';
        }
        if (type) {
            url += `/${type}`;
        }
        if (userId) {
            url += `/${userId}`;
        }
        else {
            url += '/0';
        }
        if (organizationId) {
            url += `/${organizationId}`;
        }
        if (limits) {
            url += `/${limits}`;
        }
        else {
            url += '/-999';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}/${state}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 编辑合辑
     * @param {*} item 
     * @param {*} success 
     * @param {*} error 
     */
     this.EditCollection = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Video/EditCollection';
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
     /**
     * 添加合辑
     * @param {*} item 
     * @param {*} success 
     * @param {*} error 
     */
      this.AddCollection = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Video/PutCollection';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.CollectionDetail = function (collectionId, success, error) {
        var url = ServiceBaseAddress + '/api/Video/GetVideoCollectionDetail/'+ collectionId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 合辑下视频列表
     * @param collectionId
     * @param limits
     * @param keyWord
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.GetCollectionVideo = function (collectionId, limits, keyWord, pageIndex, state, success, error) {
        var url = ServiceBaseAddress + '/api/Video/GetCollectionVideo';
        if (collectionId) {
            url += `/${collectionId}`;
        }
        if (limits) {
            url += `/${limits}`;
        }
        else {
            url += '/-999';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}/${state}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取个人/机构/总后台自己发布的合辑视频
     * @param {*} categoryId 
     * @param {*} type 
     * @param {*} userId 
     * @param {*} organizationId 
     * @param {*} keyWord 
     * @param {*} pageIndex 
     * @param {*} success 
     * @param {*} error 
     */
     this.GetMyselfCollection = function (categoryId, type, userId, organizationId,limits, keyWord, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Video/GetMyselfCollection';
        if (categoryId) {
            url += `/${categoryId}`;
        }
        else {
            url += '/0';
        }
        if (type) {
            url += `/${type}`;
        }
        if (userId) {
            url += `/${userId}`;
        }
        else {
            url += '/0';
        }
        if (organizationId) {
            url += `/${organizationId}`;
         }
         if (limits) {
            url += `/${limits}`;
        }
        else {
            url += '/-999';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取某个视频的所有订单
     * @param videoId
     * @param keyWord
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
     this.GetVideoAllOrder = function (videoId, keyWord, pageIndex,pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Video/GetVideoAllOrder';
        if (videoId) {
            url += `/${videoId}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.AddVideos = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Video/AddVideo';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.EditVideos = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Video/EditVideo';
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.VideoDetail = function (VideosId, success, error) {
        var url = ServiceBaseAddress + '/api/Video/Detail/'+ VideosId+'/false';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.Remove = function (videoId,aliyunVideoId, success, error) {
        var url = ServiceBaseAddress + '/api/Video/DeleteVideo/' + videoId+'/'+aliyunVideoId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 总机构后台--获取所有视频评论
     * @param keyWord
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
     this.VideoEvaluateList = function (keyWord,pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Video/GetAllCommentOrg';
        if (keyWord ) {
            url += `/${keyWord }`;
        }else {
            url += `/%20`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 评论
     * @param item
     * @param success
     * @param error
     * @constructor
     */
     this.VideoEvaluateReplay = function ( item, success, error) {
        var url = ServiceBaseAddress + '/api/Video/ReplyComment';
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 删除评论
     * @param commentId
     * @param success
     * @param error
     * @constructor
     */
     this.RemoveEvaluate = function (commentId, success, error) {
        var url = ServiceBaseAddress + '/api/Video/DeleteComment/' + commentId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取阿里云视频ID
     * @param success
     * @param error
     * @constructor
     */
     this.GetVideoInfo = function (videoId,success, error) {
        var url = ServiceBaseAddress + '/api/Video/VideoInfo/'+videoId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { Videos }
